import {inject} from "@angular/core";
import {PlatformChannelService} from "@bb-core/service";
import {untilDestroyed} from "@ngneat/until-destroy";

const knownSignalRMethods = "knownSignalRMethods";

export function SignalRHandler<T extends new(...args: any[]) => {}>(constructor: T) {
    return class extends constructor {
        declare [knownSignalRMethods]: KnownSignalRMethod[];

        constructor(...args: any[]) {
            super(...args);
            const platformChannel = inject(PlatformChannelService);

            for (const {hubName, eventName, methodName} of this[knownSignalRMethods]) {
                if (!(methodName in this)) {
                    continue;
                }

                platformChannel
                    .listenToEvent(hubName, eventName)
                    .pipe(untilDestroyed(this))
                    .subscribe(mArgs => (this as any)[methodName](...mArgs));
            }
        }
    };
}

type KnownSignalRMethod = { hubName: string, eventName: string, methodName: string };

export function SignalRMethod(hubName: string, eventName: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        if (!(knownSignalRMethods in target)) {
            target[knownSignalRMethods] = [] as KnownSignalRMethod[];
        }

        (target[knownSignalRMethods] as KnownSignalRMethod[]).push({hubName, eventName, methodName: propertyKey});
    };
}
